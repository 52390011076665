
const config = {
    name: 'Ivana & Jure',
    date: '28.09.2024.',
    subdomain: 'ivanaijure28092024',
    password: 'ivana289',
    maxImage: 5
  };

  // PROMIJENI INDEX TITLE
  
  export default config;
  